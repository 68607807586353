<template>
  <div class="relative w-full">
    <google-places-autocomplete
      @resultChanged="onChangingPlace"
      @resultCleared="onClearingPlace"
      v-model="value"
    >
      <div slot="input" slot-scope="{ context, events, actions }">
        <label class="font-bold text-gray-700 text-14px">
          {{ label }}
        </label>
        <input
          v-model="context.input"
          @focus="events.inputHasReceivedFocus"
          @input="events.inputHasChanged"
          @keydown.enter.prevent="actions.selectItemFromList"
          @keydown.down.prevent="actions.shiftResultsSelection"
          @keydown.up.prevent="actions.unshiftResultsSelection"
          type="search"
          autocomplete="autocomplete-off"
          :placeholder="placeholder"
          :disabled="disabled"
          :class="
            disabled
              ? 'places-autocomplete disabled'
              : 'places-autocomplete enabled'
          "
        />
      </div>

      <span slot="item" slot-scope="{ place }">
        <i class="mr-3 fas fa-map-marker-alt text-oLightGray" />
        {{ place.structured_formatting.main_text }}
        <span class="text-xs text-oLightGray">
          {{
            place.structured_formatting.secondary_text
              ? `- ${place.structured_formatting.secondary_text}`
              : ``
          }}
        </span>
      </span>

      <span slot="activeItem" slot-scope="{ place }">
        <i class="mr-3 fas fa-map-marker-alt text-oLightGray" />
        {{ place.structured_formatting.main_text }}
        <span class="text-xs text-oLightGray">
          {{
            place.structured_formatting.secondary_text
              ? `- ${place.structured_formatting.secondary_text}`
              : ``
          }}
        </span>
      </span>
    </google-places-autocomplete>
  </div>
</template>

<script>
import { GooglePlacesAutocomplete } from 'vue-better-google-places-autocomplete'

export default {
  name: 'PlacesAutocomplete',
  props: {
    value: {
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: 'Search Location',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Enter address / location',
    },
  },
  components: {
    GooglePlacesAutocomplete,
  },
  methods: {
    async onChangingPlace(e) {
      this.$emit('update:place', e)
    },
    async onClearingPlace() {
      this.$emit('clear:place')
    },
  },
}
</script>

<style lang="scss">
.places-autocomplete {
  height: 38px;
  @apply relative block w-full px-4 py-2 leading-normal border border-gray-400 rounded outline-none;

  &:focus {
    @apply outline-none border-gray-700;
  }
  &.enabled {
    @apply bg-white;
  }
  &.disabled {
    @apply bg-oLightestGray;
  }
}
.vbga-results {
  @apply absolute w-full bg-white border rounded-b-lg text-14px font-medium text-oDark;
  top: 60px;
  left: 0;
  z-index: 5;

  li {
    padding: 0.65rem;
    cursor: pointer;
    @apply truncate flex justify-between items-center cursor-pointer;

    &.highlighted {
      @apply bg-gray-300 font-semibold;
    }
  }
}
</style>

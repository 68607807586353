var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('form',{staticClass:"px-6",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitStep)}}},[_c('div',{staticClass:"mb-6 border-b"},[_c('TCheckbox',{attrs:{"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.operator.title.doNotNeedOperatorConfigCheckbox'
          ),"name":_vm.$t(
            'components.fleetManagement.addEdit.steps.operator.title.doNotNeedOperatorConfigCheckbox'
          ),"wrapped":""},model:{value:(_vm.skipOperatorApp),callback:function ($$v) {_vm.skipOperatorApp=$$v},expression:"skipOperatorApp"}})],1),_c('AppInput',{attrs:{"type":"number","step":"0.01","rules":"","label":_vm.$t(
          'components.fleetManagement.addEdit.steps.operator.title.chargingEarningRate'
        ),"name":_vm.$t(
          'components.fleetManagement.addEdit.steps.operator.title.chargingEarningRate'
        ),"placeholder":"e.g. 20","disabled":_vm.skipOperatorApp,"haveUnitText":true,"unitText":_vm.currencySymbol},model:{value:(_vm.form.charging_task.rate),callback:function ($$v) {_vm.$set(_vm.form.charging_task, "rate", $$v)},expression:"form.charging_task.rate"}}),_c('text-input',{attrs:{"rules":"","label":_vm.$t(
          'components.fleetManagement.addEdit.steps.operator.title.chargingPoint'
        ),"name":_vm.$t(
          'components.fleetManagement.addEdit.steps.operator.title.chargingPoint'
        ),"placeholder":"e.g. 5","disabled":_vm.skipOperatorApp},model:{value:(_vm.form.charging_task.point),callback:function ($$v) {_vm.$set(_vm.form.charging_task, "point", $$v)},expression:"form.charging_task.point"}}),_c('AppInput',{attrs:{"type":"number","step":"0.01","rules":"","label":_vm.$t(
          'components.fleetManagement.addEdit.steps.operator.title.rebalanceEarningRate'
        ),"name":_vm.$t(
          'components.fleetManagement.addEdit.steps.operator.title.rebalanceEarningRate'
        ),"placeholder":"e.g. 20","disabled":_vm.skipOperatorApp,"haveUnitText":true,"unitText":_vm.currencySymbol},model:{value:(_vm.form.rebalance_task.rate),callback:function ($$v) {_vm.$set(_vm.form.rebalance_task, "rate", $$v)},expression:"form.rebalance_task.rate"}}),_c('text-input',{attrs:{"rules":"","label":_vm.$t(
          'components.fleetManagement.addEdit.steps.operator.title.rebalancePoint'
        ),"name":_vm.$t(
          'components.fleetManagement.addEdit.steps.operator.title.rebalancePoint'
        ),"placeholder":"e.g. 5","disabled":_vm.skipOperatorApp},model:{value:(_vm.form.rebalance_task.point),callback:function ($$v) {_vm.$set(_vm.form.rebalance_task, "point", $$v)},expression:"form.rebalance_task.point"}}),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" Save ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
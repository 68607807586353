var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('LocationModal',{attrs:{"name":"warehouseLocationModal","updatable":"","use-geocoder":""},on:{"save":_vm.onSaveWareHouseLocation}}),_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('form',{staticClass:"px-6",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitStep)}}},[_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"text","name":_vm.$t('components.fleetManagement.addEdit.steps.details.title.name'),"label":_vm.$t('components.fleetManagement.addEdit.steps.details.title.name'),"rules":"required","infoDescription":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.infoDescription.name'
          ),"placeholder":"e.g. OTORide HQ"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.title.organization'
          ),"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.title.organization'
          ),"rules":"required","placeholder":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.placeHolder.organization'
          ),"value-attribute":"id","text-attribute":"name","infoDescription":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.infoDescription.organization'
          ),"options":_vm.organizations},model:{value:(_vm.form.organization),callback:function ($$v) {_vm.$set(_vm.form, "organization", $$v)},expression:"form.organization"}})],1),_c('div',{staticClass:"flex relative items-center mb-6"},[_c('div',{staticClass:"w-11/12"},[_c('PlacesAutocomplete',{attrs:{"label":_vm.$t(
              'components.fleetManagement.addEdit.steps.details.title.searchWarehouseLocation'
            )},on:{"update:place":_vm.onUpdateWarehousePlace,"clear:place":function () { return (_vm.place = null); }}})],1),_c('div',{staticClass:"flex justify-center items-center w-1/12 google-map"},[_c('div',{staticClass:"mt-6 mr-0 map-btn",on:{"click":function($event){return _vm.$modal.show('warehouseLocationModal')}}},[_c('i',{staticClass:"fas fa-map"})])])]),_c('div',{staticClass:"flex items-center"},[_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.title.warehouseName'
          ),"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.title.warehouseName'
          ),"rules":"required","isInfoEnabled":false,"placeholder":"","disabled":""},model:{value:(_vm.form.warehouse_name),callback:function ($$v) {_vm.$set(_vm.form, "warehouse_name", $$v)},expression:"form.warehouse_name"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.title.warehouseLongitude'
          ),"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.title.warehouseLongitude'
          ),"rules":"required","isInfoEnabled":false,"placeholder":"","disabled":""},model:{value:(_vm.form.warehouse_lon),callback:function ($$v) {_vm.$set(_vm.form, "warehouse_lon", $$v)},expression:"form.warehouse_lon"}}),_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.title.warehouseLatitude'
          ),"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.title.warehouseLatitude'
          ),"rules":"required","isInfoEnabled":false,"placeholder":"","disabled":""},model:{value:(_vm.form.warehouse_lat),callback:function ($$v) {_vm.$set(_vm.form, "warehouse_lat", $$v)},expression:"form.warehouse_lat"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.title.whatsappPhoneNumber'
          ),"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.title.whatsappPhoneNumber'
          ),"placeholder":"e.g. 0123456789","infoDescription":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.infoDescription.whatsappPhoneNumber'
          )},model:{value:(_vm.form.whatsapp_business_number),callback:function ($$v) {_vm.$set(_vm.form, "whatsapp_business_number", $$v)},expression:"form.whatsapp_business_number"}}),_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.title.supportPhoneNumber'
          ),"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.title.supportPhoneNumber'
          ),"placeholder":"e.g. 0123456789","infoDescription":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.infoDescription.supportPhoneNumber'
          )},model:{value:(_vm.form.support_phone_number),callback:function ($$v) {_vm.$set(_vm.form, "support_phone_number", $$v)},expression:"form.support_phone_number"}})],1),_c('TCheckbox',{attrs:{"label":_vm.$t(
          'components.fleetManagement.addEdit.steps.details.title.useCompanyDetailsCheckBox'
        ),"name":_vm.$t(
          'components.fleetManagement.addEdit.steps.details.title.useCompanyDetailsCheckBox'
        ),"wrapped":""},model:{value:(_vm.form.use_company_details),callback:function ($$v) {_vm.$set(_vm.form, "use_company_details", $$v)},expression:"form.use_company_details"}}),_c('br'),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"richselect","label":_vm.$t('components.fleetManagement.addEdit.steps.details.title.country'),"name":_vm.$t('components.fleetManagement.addEdit.steps.details.title.country'),"rules":"required","placeholder":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.placeHolder.country'
          ),"value-attribute":"id","text-attribute":"name","infoDescription":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.infoDescription.country'
          ),"options":_vm.countries,"variant":{
          readonly: _vm.getCompanyDetailsDisabledState,
        },"disabled":_vm.getCompanyDetailsDisabledState},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}}),_c('AppInput',{attrs:{"type":"richselect","label":_vm.$t('components.fleetManagement.addEdit.steps.details.title.city'),"name":_vm.$t('components.fleetManagement.addEdit.steps.details.title.city'),"rules":"required","placeholder":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.placeHolder.city'
          ),"value-attribute":"id","text-attribute":"name","infoDescription":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.infoDescription.city'
          ),"options":_vm.cities,"disabled":_vm.getCompanyDetailsDisabledState,"variant":{
          readonly: _vm.getCityDisabledState,
        }},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})],1),_c('AppInput',{attrs:{"type":"text","label":_vm.$t('components.fleetManagement.addEdit.steps.details.title.zipCode'),"name":_vm.$t('components.fleetManagement.addEdit.steps.details.title.zipCode'),"placeholder":"e.g. 123456","infoDescription":_vm.$t(
          'components.fleetManagement.addEdit.steps.details.infoDescription.zipCode'
        ),"disabled":_vm.getCompanyDetailsDisabledState},model:{value:(_vm.form.zip_code),callback:function ($$v) {_vm.$set(_vm.form, "zip_code", $$v)},expression:"form.zip_code"}}),_c('AppInput',{attrs:{"type":"textarea","label":_vm.$t('components.fleetManagement.addEdit.steps.details.title.address'),"name":_vm.$t('components.fleetManagement.addEdit.steps.details.title.address'),"placeholder":"","infoDescription":_vm.$t(
          'components.fleetManagement.addEdit.steps.details.infoDescription.address'
        ),"disabled":_vm.getCompanyDetailsDisabledState,"variant":{
        readonly: _vm.getCompanyDetailsDisabledState,
      }},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"text","label":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.title.registrationID'
          ),"name":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.title.registrationID'
          ),"placeholder":"e.g. 123 446 7890","infoDescription":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.infoDescription.registrationID'
          )},model:{value:(_vm.form.registration_no),callback:function ($$v) {_vm.$set(_vm.form, "registration_no", $$v)},expression:"form.registration_no"}}),_c('AppInput',{attrs:{"type":"text","label":_vm.$t('components.fleetManagement.addEdit.steps.details.title.taxID'),"name":_vm.$t('components.fleetManagement.addEdit.steps.details.title.taxID'),"placeholder":"e.g. TX123 456 7890","infoDescription":_vm.$t(
            'components.fleetManagement.addEdit.steps.details.infoDescription.taxID'
          )},model:{value:(_vm.form.tax_id),callback:function ($$v) {_vm.$set(_vm.form, "tax_id", $$v)},expression:"form.tax_id"}})],1),_c('TCheckbox',{attrs:{"label":_vm.$t(
          'components.fleetManagement.addEdit.steps.details.title.showBrandLogo'
        ),"name":_vm.$t(
          'components.fleetManagement.addEdit.steps.details.title.showBrandLogo'
        ),"wrapped":""},on:{"change":_vm.onChangeBrandLogoShowingStatus},model:{value:(_vm.showBrandLogo),callback:function ($$v) {_vm.showBrandLogo=$$v},expression:"showBrandLogo"}}),(_vm.showBrandLogo)?_c('UploadAvatar',{class:"mb-3",attrs:{"current":_vm.form.brand_logo},model:{value:(_vm.form.brand_logo),callback:function ($$v) {_vm.$set(_vm.form, "brand_logo", $$v)},expression:"form.brand_logo"}}):_vm._e(),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" Save ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
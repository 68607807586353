var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('form',{staticClass:"px-6",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitStep)}}},[_c('div',{staticClass:"py-2"},[_c('TCheckbox',{attrs:{"label":_vm.$t(
            'components.fleetManagement.addEdit.steps.schedule.title.useDefaultOrgBusinessHoursCheckbox'
          ),"name":_vm.$t(
            'components.fleetManagement.addEdit.steps.schedule.title.useDefaultOrgBusinessHoursCheckbox'
          ),"wrapped":""},model:{value:(_vm.form.use_org_business_hours),callback:function ($$v) {_vm.$set(_vm.form, "use_org_business_hours", $$v)},expression:"form.use_org_business_hours"}})],1),_c('div',{staticClass:"grid grid-cols-12 py-2 gap-x-1 "},[_c('div',{staticClass:"col-span-5"},[_c('p',{staticClass:"font-bold ",class:_vm.form.use_org_business_hours ? 'text-gray-500' : 'text-gray-800'},[_vm._v(" "+_vm._s(_vm.$t('components.fleetManagement.addEdit.steps.schedule.title.day'))+" ")])]),_c('div',{staticClass:"col-span-7"},[_c('div',{staticClass:"flex gap-x-1"},[_c('div',{staticClass:"w-1/2"},[_c('span',{staticClass:"hidden md:block font-bold",class:_vm.form.use_org_business_hours
                  ? 'text-gray-500'
                  : 'text-gray-800'},[_vm._v(" "+_vm._s(_vm.$t( 'components.fleetManagement.addEdit.steps.schedule.title.startingTime' ))+" ")]),_c('span',{staticClass:"block md:hidden font-bold ",class:_vm.form.use_org_business_hours
                  ? 'text-gray-500'
                  : 'text-gray-800'},[_vm._v("Start")])]),_c('div',{staticClass:"w-1/2"},[_c('span',{staticClass:"hidden md:block font-bold ",class:_vm.form.use_org_business_hours
                  ? 'text-gray-500'
                  : 'text-gray-800'},[_vm._v(" "+_vm._s(_vm.$t( 'components.fleetManagement.addEdit.steps.schedule.title.endingTime' ))+" ")]),_c('span',{staticClass:"block md:hidden font-bold ",class:_vm.form.use_org_business_hours
                  ? 'text-gray-500'
                  : 'text-gray-800'},[_vm._v("End")])])])])]),_vm._l((_vm.getSchedules),function(item,index){return [_c('div',{key:index,staticClass:"grid grid-cols-12 py-2 gap-x-1 ",class:index % 2 ? 'bg-gray-50' : ''},[_c('div',{staticClass:"col-span-5  flex items-center"},[_c('t-toggle',{attrs:{"variant":"success","readonly":_vm.form.use_org_business_hours},model:{value:(item.is_active),callback:function ($$v) {_vm.$set(item, "is_active", $$v)},expression:"item.is_active"}}),_c('span',{staticClass:"ml-1 md-3 font-bold text-14px  ",class:_vm.form.use_org_business_hours ? 'text-gray-500' : 'text-oGray'},[_vm._v(_vm._s(item.day))])],1),_c('div',{staticClass:"col-span-7 "},[_c('div',{staticClass:"flex gap-x-1 items-center"},[_c('div',{staticClass:"w-1/2 flex items-center"},[(_vm.form.use_org_business_hours || !item.is_active)?_c('div',{staticClass:"bg-gray-200 border-1 border-gray-700 px-5 py-1 w-full rounded-md text-gray-400"},[_c('p',[_vm._v(_vm._s(item.start_time))])]):_c('ClockPicker',{attrs:{"placeholder":"--:--"},model:{value:(item.start_time),callback:function ($$v) {_vm.$set(item, "start_time", $$v)},expression:"item.start_time"}})],1),_c('div',{staticClass:"w-1/2  flex items-center "},[(_vm.form.use_org_business_hours || !item.is_active)?_c('div',{staticClass:"bg-gray-200 border-1 border-gray-700 px-5 py-1 w-full rounded-md  text-gray-400"},[_c('p',[_vm._v(_vm._s(item.end_time))])]):_c('ClockPicker',{attrs:{"placeholder":"--:--"},model:{value:(item.end_time),callback:function ($$v) {_vm.$set(item, "end_time", $$v)},expression:"item.end_time"}})],1)])])])]}),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v("Save")])],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
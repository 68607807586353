var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('t-dropdown',{attrs:{"variant":"smActions"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
      var mousedownHandler = ref.mousedownHandler;
      var focusHandler = ref.focusHandler;
      var blurHandler = ref.blurHandler;
      var keydownHandler = ref.keydownHandler;
return [_c('button',{staticClass:"text-oDark focus:outline-none",attrs:{"aria-label":"Report Status Menu","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('svg',{staticClass:"mt-1 w-6 h-5 text-oGray hover:text-oDark",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"}})])])]}},{key:"default",fn:function(ref){
      var hide = ref.hide;
return [_c('div',{staticClass:"py-1 mt-px bg-white rounded-md shadow-md z-200",on:{"click":function($event){return hide()}}},[_c('t-dropdown-item',{attrs:{"variant":"danger"},on:{"click":_vm.askConfirmation}},[_vm._v(" Delete ")])],1)]}}])}),_c('ConfirmFirst',{key:("confirmFirst-" + (_vm.data.id)),attrs:{"id":("confirmFirst-" + (_vm.data.id)),"isLoading":_vm.isLoading,"title":"Delete Fleet?","confirmButtonText":"Delete","confirmButtonVariant":"warning-alt"},on:{"confirm":function($event){return _vm.executeAction('delete')},"cancel":_vm.handleCancellation}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
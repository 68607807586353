<template>
  <section>
    <FleetAddEdit
      :es-id="ae.esId"
      :stepper-mode="ae.stepperMode"
      :stepper-step="ae.stepperStep"
      :primary-key="ae.primaryKey"
      :raw-data="ae.rawData"
      :orgPaymentConfig="ae.orgPaymentConfig"
      :busy="ae.busy"
      :org-email-settings="orgEmailSettings"
      @refresh="$store.dispatch('fsTable/fetchData')"
    />
    <add-edit-billing-plan :vehicleTypes="getVehicleTypes" />

    <content-section :spacing="false">
      <div class="content-spacing">
        <div v-if="isShowOnlyPageTitle">
          <oto-page-title :title="pageTitle" />
        </div>

        <title-plus
          v-else
          :title="pageTitle"
          @plus="onOpenES({ primaryKey: null })"
          class="mb-10"
        />
      </div>

      <FSTable
        :fst-id="`fleets`"
        :qso="qso"
        :endpoint="endpoint"
        :headers="getTableHeaders"
      >
        <template v-slot:default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow :key="itemIndex">
                <FSTableRowItem>
                  <div class="flex items-center">
                    <router-link
                      :to="{
                        name: 'ViewFleetProfile',
                        params: { id: item.id },
                      }"
                      :class="`text-blue-600`"
                      target="_blank"
                    >
                      {{ item.name }}
                    </router-link>
                    <span
                      v-if="item.is_default_fleet_for_organization"
                      class="bg-blue-600 badge"
                      >D</span
                    >
                  </div>
                </FSTableRowItem>

                <FSTableRowItem :text="item.address" />

                <FSTableRowItem>
                  <div
                    class="flex items-center gap-2 flex-wrap"
                    v-if="item.geofence && item.geofence.id"
                  >
                    <RouterLink
                      class="text-blue-600 "
                      :to="{
                        name: 'ViewServiceArea',
                        params: { id: item.geofence.id },
                      }"
                      target="_blank"
                      >{{ item.geofence.name }}</RouterLink
                    >
                  </div>
                </FSTableRowItem>

                <FSTableRowItem :text="item.total_vehicles" />

                <FSTableRowItem>
                  <div
                    v-if="item.pricing_plans && item.pricing_plans.length > 0"
                  >
                    <div
                      v-for="(pricingPlan, key) in item.pricing_plans"
                      :key="key"
                    >
                      <p>
                        <span
                          @click="showPricingPlan(pricingPlan.id)"
                          class="font-semibold text-blue-700 cursor-pointer"
                        >
                          {{ pricingPlan.name }}
                        </span>
                        <span class="text-gray-500"
                          >({{ pricingPlan.bike_category_display_text }})</span
                        >
                      </p>
                    </div>
                  </div>
                </FSTableRowItem>

                <FSTableRowItem :text="item.updated_at" date />

                <FSTableRowItem>
                  <div class="flex items-center 111__111">
                    <OtoEditIcon @click="onOpenES({ primaryKey: item.id })" />

                    <MoreActionsDropdown
                      :key="`more-actions-${itemIndex}`"
                      :data="item"
                      @delete="$store.dispatch('fsTable/fetchData')"
                    />
                  </div>
                </FSTableRowItem>
              </FSTableRow>
            </template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem>
                  <div class="flex items-center">
                    <router-link
                      :to="{
                        name: 'ViewFleetProfile',
                        params: { id: item.id },
                      }"
                      :class="`text-blue-600`"
                      target="_blank"
                    >
                      {{ item.name }}
                    </router-link>
                    <span
                      v-if="item.is_default_fleet_for_organization"
                      class="bg-blue-600 badge"
                      >D</span
                    >
                  </div>
                </FSTableRowItem>
                <FSTableRowItem>
                  <div
                    class="flex items-center gap-2 flex-wrap"
                    v-if="item.geofence && item.geofence.id"
                  >
                    <RouterLink
                      class="text-blue-600 "
                      :to="{
                        name: 'ViewServiceArea',
                        params: { id: item.geofence.id },
                      }"
                      target="_blank"
                      >{{ item.geofence.name }}</RouterLink
                    >
                  </div>
                </FSTableRowItem>
                <FSTableRowItem>
                  <div
                    v-if="item.pricing_plans && item.pricing_plans.length > 0"
                  >
                    <div
                      v-for="(pricingPlan, key) in item.pricing_plans"
                      :key="key"
                    >
                      <p>
                        <span
                          @click="showPricingPlan(pricingPlan.id)"
                          class="font-semibold text-blue-700 cursor-pointer"
                        >
                          {{ pricingPlan.name }}
                        </span>
                        <span class="text-gray-500"
                          >({{ pricingPlan.bike_category_display_text }})</span
                        >
                      </p>
                    </div>
                  </div>
                </FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t('components.fleetManagement.table.columns.location')
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.address || '--' }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.fleetManagement.table.columns.assignedVehicles'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.total_vehicles || '--' }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t('components.fleetManagement.table.columns.updatedAt')
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{
                        getFormattedDateTime(
                          item.updated_at,
                          'D MMM YYYY, h:mm:ss a'
                        )
                      }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t('components.fleetManagement.table.columns.actions')
                      }}
                    </div>
                    <div class="col-span-5 right-text ">
                      <div class="flex items-center 111__111">
                        <OtoEditIcon
                          @click="onOpenES({ primaryKey: item.id })"
                        />

                        <MoreActionsDropdown
                          :key="`more-actions-${itemIndex}`"
                          :data="item"
                          @delete="$store.dispatch('fsTable/fetchData')"
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
    </content-section>
  </section>
</template>

<script>
import ContentSection from '@/components/layout/ContentSection'
// import ExportAsDropdown from '@/components/dropdown/ExportAsDropdown'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import TitlePlus from '@/components/ui/TitlePlus'

import { useEndpoints } from '@/composables'
import { FleetConfig } from '@/config/FleetConfig'
import { getFormattedDateTime } from '@/utils/datetime'
// import OtoSearch from '@/components/ui/OtoSearch'
import MoreActionsDropdown from '@/composites/fleet/index/MoreActionsDropdown.vue'

import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import FleetAddEdit from './FleetAddEdit.vue'
import AddEditBillingPlan from '@/views/pricing/BillingPlanAddEdit.vue'
import { BillingPlanConfig } from '@/config/BillingPlanConfig'
import { EventBus } from '@/utils'
import { vehicleTypesForOrg } from '@/utils'
export default {
  name: 'Fleet',
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
    endpoint: {
      type: String,
      default: FleetConfig.api.index,
    },
    qso: {
      type: Object,
      default: () => ({ append: '', prepend: '?' }),
    },
    isShowOnlyPageTitle: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ContentSection,
    OtoEditIcon,
    TitlePlus,
    MoreActionsDropdown,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    FleetAddEdit,
    AddEditBillingPlan,
    OtoPageTitle: () => import('@/components/ui/OtoPageTitle'),
  },

  data() {
    return {
      indexDataEndpoint: FleetConfig.api.index,
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: `${this.$t('components.fleetManagement.table.columns.name')}`,
          width: '12%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.fleetManagement.table.columns.location'
          )}`,
          width: '28%',
          sort: 'address',
        },
        {
          text: `${this.$t(
            'components.fleetManagement.table.columns.serviceAreaName'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.fleetManagement.table.columns.assignedVehicles'
          )}`,
          width: '10%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.fleetManagement.table.columns.pricingPlan'
          )}`,
          width: '20%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.fleetManagement.table.columns.updatedAt'
          )}`,
          width: '15%',
          sort: 'updated_at',
        },
        {
          text: `${this.$t(
            'components.fleetManagement.table.columns.actions'
          )}`,
          width: '10%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: `${this.$t('components.fleetManagement.table.columns.name')}`,
          width: '30%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.fleetManagement.table.columns.serviceAreaName'
          )}`,
          width: '30%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.fleetManagement.table.columns.pricingPlan'
          )}`,
          width: '30%',
          sort: null,
        },
      ],
      vehicleTypes: [],
      orgEmailSettings: {},

      // add-edit
      ae: {
        // EdgeStack component
        esId: 'fleet-add-edit',

        // XStepper component
        stepperMode: 'strict',
        stepperStep: 1,

        // Data
        busy: true,
        rawData: null,
        orgPaymentConfig: null,
        primaryKey: null,
      },
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
    hasOperatorApp() {
      // console.log('hasOperatorApp?', this.$org.subscription.operator_app_enabled)
      return this.$org.subscription.operator_app_enabled
    },
    getVehicleTypes() {
      let vehicleTypes = []

      const orgVehicleTypes = this?.$org?.vehicle_type || []
      orgVehicleTypes.forEach((t) => vehicleTypes.push(vehicleTypesForOrg[t]))
      console.log('vehicleTypes', vehicleTypes)
      return vehicleTypes
    },
  },
  async created() {
    await this.$http
      .get(useEndpoints.settings.email.index(this.orgId))
      .then(({ data }) => {
        // replace null as empty string
        const formatted = {}
        for (const k in data) {
          formatted[k] = data[k] === 'null' ? '' : data[k]
        }

        this.orgEmailSettings = formatted
      })
      .catch((err) => {
        console.log('orgEmailSettingsErr', err.response)
        this.$notify({
          group: 'bottomRight',
          type: 'error',
          title: 'HTTP Error',
          text: 'Failed to load org email settings!',
        })
      })
  },

  methods: {
    getFormattedDateTime,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    async showPricingPlan(id) {
      console.log('showPricingPlan', id)

      let billingDetails = await this.$http
        .get(BillingPlanConfig.api.details(id))
        .then((res) => res.data)
        .catch((err) => console.log(err))
      billingDetails.tax_plan = billingDetails.tax_plan
        ? billingDetails.tax_plan.id
        : null
      billingDetails.existing_fleet = billingDetails.fleet
      billingDetails.fleet = billingDetails.fleet
        ? billingDetails.fleet.id
        : null
      console.log('Morphed...', billingDetails)

      EventBus.$emit(BillingPlanConfig.events.editingData, billingDetails)
      dispatchEvent(new Event(BillingPlanConfig.events.sorToggle))
    },
    async onOpenES({ primaryKey }) {
      if (typeof primaryKey === 'string') {
        this.ae.busy = true
        this.$edgeStack.open(this.ae.esId)

        const fleetReq = this.$http.get(useEndpoints.fleet.details(primaryKey))
        const orgPaymentConfigReq = await this.$http.get(
          useEndpoints.organization.details(
            this.$store.getters['auth/organizationInfo'].id
          )
        )

        const requests = [fleetReq, orgPaymentConfigReq]
        if (this.hasOperatorApp) {
          const operatorChargingReq = this.$http.get(
            useEndpoints.fleet.operator.charging(primaryKey)
          )
          const operatorRebalanceReq = this.$http.get(
            useEndpoints.fleet.operator.rebalance(primaryKey)
          )

          requests.push(operatorChargingReq, operatorRebalanceReq)
        }

        await this.$http
          .all(requests)
          .then(
            this.$http.spread((...responses) => {
              // console.log(fleetRes, chargingRes, rebalanceRes)
              const [
                fleetRes,
                orgPaymentConfigRes,
                chargingRes,
                rebalanceRes,
              ] = responses
              this.ae.orgPaymentConfig =
                orgPaymentConfigRes.data.default_payment_gateway_config
              let data = {}
              if (this.hasOperatorApp) {
                data = {
                  ...fleetRes.data,
                  charging_task: chargingRes.data,
                  rebalance_task: rebalanceRes.data,
                }
              } else {
                const [fleetRes] = responses
                data = {
                  ...fleetRes.data,
                  charging_task: {
                    point: '',
                    rate: '',
                  },
                  rebalance_task: {
                    point: '',
                    rate: '',
                  },
                }
              }

              // console.log(data)

              this.ae.rawData = data
              this.ae.primaryKey = primaryKey
              this.ae.stepperMode = 'free'

              this.ae.busy = false

              // console.log(this.ae.rawData)
              // ** open a random step (keeping it as a reference)
              // function randomNumber(min, max) {
              //   const r = Math.random() * (max - min) + min
              //   return Math.floor(r)
              // }
              // this.$edgeStack.emitter.on(
              //   this.$edgeStack.getEventName('opened', this.ae.esId),
              //   () => {
              //     this.$xStepper.navigate(this.ae.esId).to(randomNumber(0, 4))
              //   }
              // )
            })
          )
          .catch((err) => {
            console.warn({ err })

            this.$edgeStack.close(this.ae.esId)
            this.$notify({
              group: 'bottomRight',
              type: 'error',
              title: `Server Error`,
              text: 'Failed to retrieve data. Please try again.',
            })
          })

        return
      }

      this.ae.stepperMode = 'strict'

      this.ae.busy = false
      this.ae.rawData = null
      this.ae.primaryKey = null

      this.$edgeStack.open(this.ae.esId)
      // this.$xStepper.navigate(this.ae.esId).to(4)
    },

    getActionsEndpoint(id) {
      return FleetConfig.api.update(id)
    },
  },
}
</script>

<style lang="scss" scoped>
.badge {
  border-radius: 50%;
  padding: 2px;
  margin-left: 8px;
  color: #fff;
  width: 23px;
  height: 23px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>

<template>
  <Fleet
    :qso="qso"
    :endpoint="endpoint"
    :pageTitle="$t('components.fleetManagement.headline')"
    :isShowOnlyPageTitle="true"
  />
</template>

<script>
import Fleet from '@/views/fleet/index.vue'
import { FleetConfig } from '@/config/FleetConfig'
export default {
  name: 'ViewOrganizationFleets',

  components: {
    Fleet,
  },
  data() {
    return {
      qso: { append: '', prepend: '&' },
      endpoint: null,
    }
  },
  created() {
    this.generateEndPoint()
  },

  methods: {
    generateEndPoint() {
      this.endpoint =
        FleetConfig.api.index + `?organization=${this.$route.params.id}`
    },
  },
}
</script>

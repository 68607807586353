<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form @submit.prevent="handleSubmit(submitStep)" class="px-6">
      <div class="py-2">
        <TCheckbox
          v-model="form.use_org_business_hours"
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.schedule.title.useDefaultOrgBusinessHoursCheckbox'
            )
          "
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.schedule.title.useDefaultOrgBusinessHoursCheckbox'
            )
          "
          wrapped
        />
      </div>
      <div class="grid grid-cols-12 py-2 gap-x-1 ">
        <div class="col-span-5">
          <p
            class="font-bold "
            :class="
              form.use_org_business_hours ? 'text-gray-500' : 'text-gray-800'
            "
          >
            {{
              $t('components.fleetManagement.addEdit.steps.schedule.title.day')
            }}
          </p>
        </div>
        <div class="col-span-7">
          <div class="flex gap-x-1">
            <div class="w-1/2">
              <span
                class="hidden md:block font-bold"
                :class="
                  form.use_org_business_hours
                    ? 'text-gray-500'
                    : 'text-gray-800'
                "
              >
                {{
                  $t(
                    'components.fleetManagement.addEdit.steps.schedule.title.startingTime'
                  )
                }}
              </span>
              <span
                class="block md:hidden font-bold "
                :class="
                  form.use_org_business_hours
                    ? 'text-gray-500'
                    : 'text-gray-800'
                "
                >Start</span
              >
            </div>
            <div class="w-1/2">
              <span
                class="hidden md:block font-bold "
                :class="
                  form.use_org_business_hours
                    ? 'text-gray-500'
                    : 'text-gray-800'
                "
              >
                {{
                  $t(
                    'components.fleetManagement.addEdit.steps.schedule.title.endingTime'
                  )
                }}
              </span>
              <span
                class="block md:hidden font-bold "
                :class="
                  form.use_org_business_hours
                    ? 'text-gray-500'
                    : 'text-gray-800'
                "
                >End</span
              >
            </div>
          </div>
        </div>
      </div>
      <template v-for="(item, index) in getSchedules">
        <div
          :key="index"
          class="grid grid-cols-12 py-2 gap-x-1 "
          :class="index % 2 ? 'bg-gray-50' : ''"
        >
          <div class="col-span-5  flex items-center">
            <t-toggle
              variant="success"
              v-model="item.is_active"
              :readonly="form.use_org_business_hours"
            />
            <span
              class="ml-1 md-3 font-bold text-14px  "
              :class="
                form.use_org_business_hours ? 'text-gray-500' : 'text-oGray'
              "
              >{{ item.day }}</span
            >
          </div>
          <div class="col-span-7 ">
            <div class="flex gap-x-1 items-center">
              <div class="w-1/2 flex items-center">
                <div
                  v-if="form.use_org_business_hours || !item.is_active"
                  class="bg-gray-200 border-1 border-gray-700 px-5 py-1 w-full rounded-md text-gray-400"
                >
                  <p>{{ item.start_time }}</p>
                </div>
                <ClockPicker
                  v-else
                  v-model="item.start_time"
                  placeholder="--:--"
                />
              </div>
              <div class="w-1/2  flex items-center ">
                <div
                  v-if="form.use_org_business_hours || !item.is_active"
                  class="bg-gray-200 border-1 border-gray-700 px-5 py-1 w-full rounded-md  text-gray-400"
                >
                  <p>{{ item.end_time }}</p>
                </div>
                <ClockPicker
                  v-else
                  v-model="item.end_time"
                  placeholder="--:--"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <button type="submit" ref="submitButton" class="hidden">Save</button>
    </form>
  </ValidationObserver>
</template>

<script>
import ClockPicker from '@/components/picker/clock/components/ClockPicker'
import { useEndpoints } from '@/composables'
export default {
  name: 'Step4',
  components: {
    ClockPicker,
  },

  props: {
    primaryKey: {
      required: true,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isLoading: true,
      form: {
        use_org_business_hours: null,
        fleet_schedules: [],
        org_schedules: [],
      },
    }
  },
  created() {
    if (!this.formData) {
      this.$http
        .get(useEndpoints.fleet.details(this.primaryKey))
        .then((res) => {
          this.form.use_org_business_hours = res.data.use_org_business_hours

          res.data.business_hour_settings.forEach((item) => {
            this.mapDataWithModel('fleet', item)
          })
          res.data.org_business_hour_settings.forEach((item) => {
            this.mapDataWithModel('org', item)
          })
        })
        .catch((err) => console.log(err))
        .finally(() => (this.isLoading = false))
    }
  },
  watch: {
    formData: {
      deep: true,
      immediate: true,
      handler(data) {
        if (data) {
          this.isLoading = false
          this.form.use_org_business_hours = data.use_org_business_hours
          data.business_hour_settings.forEach((item) => {
            this.mapDataWithModel('fleet', item)
          })
          data.org_business_hour_settings.forEach((item) => {
            this.mapDataWithModel('org', item)
          })
        }
      },
    },
  },
  computed: {
    getSchedules() {
      if (this.form.use_org_business_hours) {
        return this.form.org_schedules
      } else {
        return this.form.fleet_schedules
      }
    },
  },
  methods: {
    async submit() {
      await this.$refs.submitButton.click()
    },
    async submitStep() {
      this.isLoading = true
      const fleetMethod = 'PATCH'
      const fleetURL = useEndpoints.fleet.update(this.primaryKey)
      const fleetData = new FormData()
      fleetData.append(
        'use_org_business_hours',
        this.form.use_org_business_hours
      )
      const fleetReq = this.$http({
        url: fleetURL,
        method: fleetMethod,
        data: fleetData,
      })
      let requests = [fleetReq]

      if (!this.form.use_org_business_hours) {
        let scheduleRequests = []
        if (this.form.fleet_schedules.length) {
          this.form.fleet_schedules.forEach((element) => {
            const scheduleMethod = 'PATCH'
            const scheduleURL = useEndpoints.settings.businessHour.update(
              element.id
            )
            const scheduleData = new FormData()
            scheduleData.append('is_active', element.is_active)
            scheduleData.append('start_time', element.start_time)
            scheduleData.append('end_time', element.end_time)

            const scheduleReq = this.$http({
              url: scheduleURL,
              method: scheduleMethod,
              data: scheduleData,
            })
            scheduleRequests.push(scheduleReq)
          })
          scheduleRequests.forEach((eR) => requests.push(eR))
        }
      }

      await this.$http
        .all(requests)
        .then(
          this.$http.spread((...responses) => {
            console.log('step-res', responses)

            const message = `Fleet Settings updated successfully`

            this.$notify({
              group: 'bottomLeft',
              type: 'success',
              title: 'Success',
              text: message,
            })
            this.$emit('save', { step: 4 })
          })
        )
        .catch((err) => {
          console.error('Error occured!', err)
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occured!',
            text: err.response.data.detail,
          })
        })
        .finally(() => (this.isLoading = false))
    },
    formatTimeForClockPicker(time) {
      let parts = time.split(':')
      return parts[0] + ':' + parts[1]
    },
    mapDataWithModel(type, item) {
      if (type === 'org') {
        this.form.org_schedules.push({
          id: item.id,
          day: item.day,
          is_active: item.is_active,
          start_time: this.formatTimeForClockPicker(item.start_time),
          end_time: this.formatTimeForClockPicker(item.end_time),
        })
      }
      if (type === 'fleet') {
        this.form.fleet_schedules.push({
          id: item.id,
          day: item.day,
          is_active: item.is_active,
          start_time: this.formatTimeForClockPicker(item.start_time),
          end_time: this.formatTimeForClockPicker(item.end_time),
        })
      }
    },
  },
}
</script>
<style lang=""></style>

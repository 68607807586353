<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <LocationModal
      name="warehouseLocationModal"
      updatable
      use-geocoder
      @save="onSaveWareHouseLocation"
    />

    <loading :active.sync="isLoading" :is-full-page="false" />

    <form @submit.prevent="handleSubmit(submitStep)" class="px-6">
      <div class="grid grid-cols-2 gap-4">
        <AppInput
          v-model="form.name"
          type="text"
          :name="
            $t('components.fleetManagement.addEdit.steps.details.title.name')
          "
          :label="
            $t('components.fleetManagement.addEdit.steps.details.title.name')
          "
          rules="required"
          :infoDescription="
            $t(
              'components.fleetManagement.addEdit.steps.details.infoDescription.name'
            )
          "
          placeholder="e.g. OTORide HQ"
        />

        <AppInput
          v-model="form.organization"
          type="richselect"
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.details.title.organization'
            )
          "
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.details.title.organization'
            )
          "
          rules="required"
          :placeholder="
            $t(
              'components.fleetManagement.addEdit.steps.details.placeHolder.organization'
            )
          "
          value-attribute="id"
          text-attribute="name"
          :infoDescription="
            $t(
              'components.fleetManagement.addEdit.steps.details.infoDescription.organization'
            )
          "
          :options="organizations"
        />
      </div>
      <!-- warehouse -->
      <div class="flex relative items-center mb-6">
        <div class="w-11/12">
          <PlacesAutocomplete
            :label="
              $t(
                'components.fleetManagement.addEdit.steps.details.title.searchWarehouseLocation'
              )
            "
            @update:place="onUpdateWarehousePlace"
            @clear:place="() => (place = null)"
          />
        </div>

        <div class="flex justify-center items-center w-1/12 google-map">
          <div
            class="mt-6 mr-0 map-btn"
            @click="$modal.show('warehouseLocationModal')"
          >
            <i class="fas fa-map" />
          </div>
        </div>
      </div>

      <div class="flex items-center">
        <AppInput
          v-model="form.warehouse_name"
          type="text"
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.details.title.warehouseName'
            )
          "
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.details.title.warehouseName'
            )
          "
          rules="required"
          :isInfoEnabled="false"
          placeholder=""
          disabled
        />
      </div>

      <div class="grid grid-cols-2 gap-4">
        <AppInput
          v-model="form.warehouse_lon"
          type="text"
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.details.title.warehouseLongitude'
            )
          "
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.details.title.warehouseLongitude'
            )
          "
          rules="required"
          :isInfoEnabled="false"
          placeholder=""
          disabled
        />
        <AppInput
          v-model="form.warehouse_lat"
          type="text"
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.details.title.warehouseLatitude'
            )
          "
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.details.title.warehouseLatitude'
            )
          "
          rules="required"
          :isInfoEnabled="false"
          placeholder=""
          disabled
        />
      </div>

      <div class="grid grid-cols-2 gap-4">
        <AppInput
          v-model="form.whatsapp_business_number"
          type="text"
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.details.title.whatsappPhoneNumber'
            )
          "
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.details.title.whatsappPhoneNumber'
            )
          "
          placeholder="e.g. 0123456789"
          :infoDescription="
            $t(
              'components.fleetManagement.addEdit.steps.details.infoDescription.whatsappPhoneNumber'
            )
          "
        />
        <AppInput
          v-model="form.support_phone_number"
          type="text"
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.details.title.supportPhoneNumber'
            )
          "
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.details.title.supportPhoneNumber'
            )
          "
          placeholder="e.g. 0123456789"
          :infoDescription="
            $t(
              'components.fleetManagement.addEdit.steps.details.infoDescription.supportPhoneNumber'
            )
          "
        />
      </div>

      <TCheckbox
        v-model="form.use_company_details"
        :label="
          $t(
            'components.fleetManagement.addEdit.steps.details.title.useCompanyDetailsCheckBox'
          )
        "
        :name="
          $t(
            'components.fleetManagement.addEdit.steps.details.title.useCompanyDetailsCheckBox'
          )
        "
        wrapped
      />

      <br />

      <div class="grid grid-cols-2 gap-4">
        <AppInput
          v-model="form.country"
          type="richselect"
          :label="
            $t('components.fleetManagement.addEdit.steps.details.title.country')
          "
          :name="
            $t('components.fleetManagement.addEdit.steps.details.title.country')
          "
          rules="required"
          :placeholder="
            $t(
              'components.fleetManagement.addEdit.steps.details.placeHolder.country'
            )
          "
          value-attribute="id"
          text-attribute="name"
          :infoDescription="
            $t(
              'components.fleetManagement.addEdit.steps.details.infoDescription.country'
            )
          "
          :options="countries"
          :variant="{
            readonly: getCompanyDetailsDisabledState,
          }"
          :disabled="getCompanyDetailsDisabledState"
        />
        <AppInput
          v-model="form.city"
          type="richselect"
          :label="
            $t('components.fleetManagement.addEdit.steps.details.title.city')
          "
          :name="
            $t('components.fleetManagement.addEdit.steps.details.title.city')
          "
          rules="required"
          :placeholder="
            $t(
              'components.fleetManagement.addEdit.steps.details.placeHolder.city'
            )
          "
          value-attribute="id"
          text-attribute="name"
          :infoDescription="
            $t(
              'components.fleetManagement.addEdit.steps.details.infoDescription.city'
            )
          "
          :options="cities"
          :disabled="getCompanyDetailsDisabledState"
          :variant="{
            readonly: getCityDisabledState,
          }"
        />
      </div>

      <AppInput
        v-model="form.zip_code"
        type="text"
        :label="
          $t('components.fleetManagement.addEdit.steps.details.title.zipCode')
        "
        :name="
          $t('components.fleetManagement.addEdit.steps.details.title.zipCode')
        "
        placeholder="e.g. 123456"
        :infoDescription="
          $t(
            'components.fleetManagement.addEdit.steps.details.infoDescription.zipCode'
          )
        "
        :disabled="getCompanyDetailsDisabledState"
      />

      <AppInput
        v-model="form.address"
        type="textarea"
        :label="
          $t('components.fleetManagement.addEdit.steps.details.title.address')
        "
        :name="
          $t('components.fleetManagement.addEdit.steps.details.title.address')
        "
        placeholder=""
        :infoDescription="
          $t(
            'components.fleetManagement.addEdit.steps.details.infoDescription.address'
          )
        "
        :disabled="getCompanyDetailsDisabledState"
        :variant="{
          readonly: getCompanyDetailsDisabledState,
        }"
      />

      <div class="grid grid-cols-2 gap-4">
        <AppInput
          v-model="form.registration_no"
          type="text"
          :label="
            $t(
              'components.fleetManagement.addEdit.steps.details.title.registrationID'
            )
          "
          :name="
            $t(
              'components.fleetManagement.addEdit.steps.details.title.registrationID'
            )
          "
          placeholder="e.g. 123 446 7890"
          :infoDescription="
            $t(
              'components.fleetManagement.addEdit.steps.details.infoDescription.registrationID'
            )
          "
        />
        <AppInput
          v-model="form.tax_id"
          type="text"
          :label="
            $t('components.fleetManagement.addEdit.steps.details.title.taxID')
          "
          :name="
            $t('components.fleetManagement.addEdit.steps.details.title.taxID')
          "
          placeholder="e.g. TX123 456 7890"
          :infoDescription="
            $t(
              'components.fleetManagement.addEdit.steps.details.infoDescription.taxID'
            )
          "
        />
      </div>
      <TCheckbox
        v-model="showBrandLogo"
        :label="
          $t(
            'components.fleetManagement.addEdit.steps.details.title.showBrandLogo'
          )
        "
        :name="
          $t(
            'components.fleetManagement.addEdit.steps.details.title.showBrandLogo'
          )
        "
        wrapped
        @change="onChangeBrandLogoShowingStatus"
      />
      <UploadAvatar
        v-if="showBrandLogo"
        v-model="form.brand_logo"
        :current="form.brand_logo"
        :class="`mb-3`"
      />
      <button type="submit" ref="submitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
// import { gmapApi } from 'vue2-google-maps'

import { useEndpoints } from '@/composables'
import { deepCompareObjects } from '@/utils'

import PlacesAutocomplete from '@/components/map/PlacesAutocomplete.vue'

import { LocationModal } from '@/components/modal'
import { UploadAvatar } from '@/components/form'
export default {
  name: 'Step1',

  components: {
    PlacesAutocomplete,
    LocationModal,
    UploadAvatar,
  },

  props: {
    organizations: {
      required: true,
    },
    countries: {
      required: true,
    },
    primaryKey: {
      type: null,
      required: false,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,

      form: {
        name: '',
        organization: '',

        warehouse_name: '',
        warehouse_lat: '',
        warehouse_lon: '',
        use_company_details: false,

        country: '',
        city: '',
        zip_code: '',
        address: '',
        whatsapp_business_number: '',
        support_phone_number: '',
        brand_logo: null,
      },
      showBrandLogo: false,
      cities: [],
      cityIsLoading: false,
    }
  },

  computed: {
    // google: gmapApi,

    useCompanyDetails() {
      return this.form.use_company_details
    },

    country() {
      return this.form.country
    },

    getCountry: function() {
      return this.form.country || undefined
    },

    getOrganization: function() {
      return this.form.organization || undefined
    },

    getCityDisabledState: function() {
      if (!this.form.country) return true
      if (this.cityIsLoading) return true
      if (this.getCompanyDetailsDisabledState) return false
      return false
    },

    getCityPlaceholder() {
      if (!this.form.country) return 'Select a country first'
      if (this.cityIsLoading) return 'Loading ⟳'
      return 'Select a city'
    },

    getUseCompanyDetails: function() {
      return this.form.use_company_details
    },

    getCompanyDetailsDisabledState: function() {
      return !!this.getUseCompanyDetails
    },
  },

  watch: {
    // sync props.formData with $data.from
    formData: {
      immediate: true,
      deep: true,
      handler(data) {
        if (data) {
          this.form = { ...data }

          this.showBrandLogo = !!this.form.brand_logo
        }
      },
    },

    // notify form is dirty & user should confirm before exiting
    form: {
      immediate: false,
      deep: true,
      handler(updatedFormData) {
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        this.$emit('dirty', { step: 1, data: this.form })
      },
    },

    useCompanyDetails(c) {
      if (c === true) {
        this.form.country = this.$org.country.id

        // cities are asynchronously loaded after selecting country
        // seeding locally as it'll likely fail to load in such a short moment
        this.cities = [this.$org.city] // todo: need to use placeholder

        this.form.city = this.$org.city.id

        this.form.zip_code = this.$org.zip_code
        this.form.address = this.$org.address
      }
    },

    country: {
      immediate: true,
      deep: false,
      async handler(countryId) {
        // this.form.city =
        this.cityIsLoading = true
        this.cities = await this.$http
          .get(useEndpoints.dropdown.city(countryId))
          .then((res) => res.data.data)
          .catch((err) => console.log(err)) // toast?
          .finally(() => (this.cityIsLoading = false))
      },
    },
  },

  methods: {
    onChangeBrandLogoShowingStatus(value) {
      this.showBrandLogo = value
    },
    async submit() {
      this.$refs.submitButton.click()
    },

    async submitStep() {
      // if it's in add mode, user shouldn't be able to re submit step 1
      // or if the api supports it, it needs to be a patch request
      // if primaryKey is already set & mode is strict -> it means user is trying
      // to get back to step 1 & resubmit

      const method = this.isEditing ? 'PATCH' : 'POST'
      const url = this.isEditing
        ? useEndpoints.fleet.update(this.primaryKey)
        : useEndpoints.fleet.create()

      if (!this.showBrandLogo) {
        this.form.brand_logo = ''
      }
      const formDataProxy = { ...this.form }
      if (this.showBrandLogo) {
        if (
          typeof this.form.brand_logo === 'string' ||
          this.form.brand_logo == null
        ) {
          delete formDataProxy.brand_logo
        }
      }

      const data = new FormData()
      for (const key in formDataProxy) {
        data.append(key, formDataProxy[key])
      }
      console.log('dta-a', data)
      this.isLoading = true
      await this.$http({ method, url, data })
        .then((res) => {
          this.$emit('save', { step: 1, data: res.data })

          const message = `Fleet ${
            this.isEditing ? 'updated' : 'added'
          } successfully`

          this.$notify({
            group: 'bottomLeft',
            type: 'success',
            title: 'Success',
            text: message,
          })
        })
        .catch((err) => {
          // todo: emit fail?
          console.error('Error occured!', err)
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occured!',
            text: err.response.data.detail,
          })
        })
        .finally(() => (this.isLoading = false))
    },

    showGooglemapModal() {},

    onUpdateWarehousePlace(data) {
      console.log('onplace', data)
      this.form.warehouse_lat = data.geometry.location.lat()
      this.form.warehouse_lon = data.geometry.location.lng()
      this.form.warehouse_name = data.formatted_address
    },

    onSaveWareHouseLocation({ marker, geocoder }) {
      this.form.warehouse_lat = marker.lat
      this.form.warehouse_lon = marker.lng
      this.form.warehouse_name =
        geocoder?.formatted_address || 'Unknown Location!'
    },
  },
}
</script>

<style lang="scss" scoped>
.map-btn {
  cursor: pointer;
  width: 25px;
  height: 25px;
  background: #ff598a;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
